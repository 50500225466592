
.Smartphone-Container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.Smartphone-Container {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    position: relative;
    z-index: 1;
}
.Smartphone-Header {
    position: relative;
    z-index: 1;
}
.Smartphone-Frame {
    position: relative;
    z-index: 10;
}